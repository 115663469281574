import { HEADERS_MAP } from '@/configs/axios';
import { AndroidService } from '@/service/Android';
import { StorageService } from '@/service/StorageService';

export class TrackingData {
  constructor(obj) {
    this.androidId = obj.androidId;
    this.appSetId = obj.appSetId;
    this.deviceModel = obj.deviceModel;
    this.drmId = obj.drmId;
    this.gaId = obj.gaId;
    this.gsfId = obj.gsfId;
    this.uniqueId = obj.uniqueId;
    this.packageId = obj.packageId;
  }

  static from(obj) {
    if (!obj) return null;
    return new TrackingData({
      androidId: obj.android_id,
      appSetId: obj.app_set_id,
      deviceModel: obj.device_model,
      drmId: obj.drm_id,
      gaId: obj.ga_id,
      gsfId: obj.gsf_id,
      uniqueId: obj.unique_id,
      packageId: obj.package_id,
    });
  }

  static toHttpObject(obj) {
    if (!obj) return null;
    return {
      android_id: obj.androidId,
      app_set_id: obj.appSetId,
      device_model: obj.deviceModel,
      drm_id: obj.drmId,
      ga_id: obj.gaId,
      adv_id: obj.gaId,
      gsf_id: obj.gsfId,
      unique_id: obj.uniqueId,
      p_id: obj.packageId,
    };
  }
  static toHttpGetUserObject(obj) {
    if (!obj) return null;
    return {
      android_id: obj.androidId,
      app_set_id: obj.appSetId,
      device_model: obj.deviceModel,
      drm_id: obj.drmId,
      ga_id: obj.gaId,
      adv_id: obj.gaId,
      gsf_id: obj.gsfId,
      unique_id: obj.uniqueId,
      package_id: obj.packageId,
    };
  }
}

export class User {
  constructor(obj) {
    this.userId = obj.userId;
    this.email = obj.email;
    this.fullName = obj.fullName;
    this.phoneNumber = obj.phoneNumber;
    this.name = obj.name;
    this.firstName = this.fullName?.split(' ')[0];
    /**
     * @type {UserAvatar}
     * */

    this.avatar = obj.avatar;
    this.createdAt = obj.createdAt;
  }
  static from(obj) {
    if (!obj) return null;
    return new User({
      userId: obj.user_id,
      email: obj.email,
      fullName: obj.full_name,
      phoneNumber: obj.phone_number,
      name: obj.name,
      avatar: UserAvatar.from(obj.avatar),
      createdAt: obj.created_at_unix * 1000,
    });
  }
  static fromAll(data) {
    if (!data) return null;
    return data.map(User.from);
  }

  static toHttpObject(data) {
    if (!data) return null;
    return {
      user_id: data.userId,
      email: data.email,
      full_name: data.fullName,
      phone_number: data.phoneNumber,
      name: data.name,
      avatar: data.avatar,
      created_at_unix: data.createdAt,
    };
  }

  static setCredentials({
    countryCode,
    access_token,
    refresh_token,
    wid,
    userId,
  }) {
    StorageService.setCountryCode(countryCode);
    StorageService.setAccessToken(access_token);
    StorageService.setAuthRefreshToken(refresh_token);
    StorageService.setWId(wid);
    StorageService.setUserId(userId);
    const pId = AndroidService.getProfileId();
    StorageService.set(HEADERS_MAP.X_PROFILE, pId);

    const packageName = AndroidService.getPackageName();
    StorageService.set(HEADERS_MAP.X_PN, packageName);

    const d = AndroidService.getAppMeta(packageName);
    StorageService.set(HEADERS_MAP.APPN, String(d.versionNumber));

    const { model } = AndroidService.getDeviceMeta();
    StorageService.set(HEADERS_MAP.X_DEVICE_ID, model);

    const { device_model } = AndroidService.getDeviceIdentifiers();
    StorageService.set(HEADERS_MAP.X_DEVICE_MODEL, device_model);
  }
  static setProfileId(profileId) {
    const pId = profileId || AndroidService.getProfileId();
    StorageService.set(HEADERS_MAP.X_PROFILE, pId);
  }
}

export class UserAvatar {
  constructor(obj) {
    this.avatarCode = obj.avatarCode;
    this.imgUrl = obj.imgUrl;
  }
  static from(obj) {
    if (!obj) return null;
    return new UserAvatar({
      avatarCode: obj.avatar_code,
      imgUrl: obj.img_url,
    });
  }
}

export class Wallet {
  constructor(obj) {
    this.label = obj.label;
    this.wid = obj.wid;
  }

  static from(obj) {
    if (!obj) return null;
    return new Wallet({
      label: obj.label,
      wid: obj.wid,
    });
  }

  static fromAll(data) {
    if (!data) return null;
    return data.map(Wallet.from);
  }
}

export class Profile {
  constructor(obj) {
    this.profileId = obj.profileId;
  }

  static from(obj) {
    if (!obj) return null;
    return new Profile({
      profileId: obj.profile_id,
    });
  }
}

export class UserEarning {
  constructor(obj) {
    /**
     *  @type {_Earning}
     */
    this.totalEarned = obj.totalEarned;
  }

  static from(obj) {
    if (!obj) return null;
    return new UserEarning({
      totalEarned: _Earning.from(obj.total_earned),
    });
  }
}

export class _Earning {
  constructor(obj) {
    this.amount = obj.amount;
    this.currency = obj.currency;
  }

  static from(obj) {
    if (!obj) return null;
    return new _Earning({
      amount: obj.amount,
      currency: obj.currency,
    });
  }
}
