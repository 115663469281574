import { ggSentry } from '@/configs/sentry';
import axiosRetry from 'axios-retry';

export const makeAxiosRetry = axiosInstance => {
  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: getRetryDelay,
    shouldResetTimeout: true,
    retryCondition: error => {
      return (
        error.response?.status === 404 ||
        error.code === 'ECONNABORTED' ||
        error.code === 'ECONNRESET' ||
        error.code === 'ETIMEDOUT' ||
        error.code === 'ENOTFOUND' ||
        error.code === 'ECONNREFUSED' ||
        error.code === 'ERR_NETWORK'
      );
    },
    onRetry: (retryCount, err, config) => {
      addToRetriedRequest(config, retryCount);
      config.headers['x-retry-count'] = retryCount;
      config.isRetried = true;
      ggSentry.catchExceptions(getRetryErrorMessage(err, retryCount));
      return config;
    },
  });
};

const addToRetriedRequest = (config, retryCount) => {
  if (!window.retriedRequestsMap) {
    window.retriedRequestsMap = new RetryRequestMap();
  }

  window.retriedRequestsMap.setRequest(config, retryCount);
};

const getRetryErrorMessage = (err, retryCount) => {
  return `Retried API call :${
    err.config.baseURL + err.config.url
  }, \n retry count : ${retryCount} \n error : ${err.request?.status} - ${
    err.code
  } - ${err.message}`;
};

const getRetryDelay = retryCount => {
  switch (retryCount) {
    case 1:
      return 500;
    case 2:
      return 2000;
    case 3:
      return 5000;
  }
};

class RetryRequestMap extends Map {
  hasRequest(url) {
    return this.has(url);
  }
  popRequest(url) {
    const request = this.get(url);
    this.delete(url);
    return request;
  }
  setRequest(config, retryCount) {
    return this.set(config.url, {
      url: config.url,
      fullUrl: config.baseURL + config.url,
      retryCount,
    });
  }
}
