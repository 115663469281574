import { apiAxiosV1 } from '@/configs/axios';
import { StorageService } from './StorageService';
import { AndroidService } from './Android';
import { API_END_POINT } from '@/configs/config';

export const AuthService = {
  async handleGooglelogin(code) {
    const url = `/user/google_auth/login`;
    const res = await apiAxiosV1.post(url, { code });
    return res?.data;
  },
  async sendMobileOtp(phoneNumber, recaptchaToken, isRetry) {
    const url = API_END_POINT + `/v1.1/user/otp`;
    const headers = {
      'x-token': recaptchaToken,
      'x-platform': 'web',
    };
    const payload = {
      phone_number: String(phoneNumber),
      is_retry: isRetry,
      retry_method: '',
    };
    const res = await apiAxiosV1.post(url, payload, {
      headers: headers,
    });
    return res?.data;
  },
  async handleMobileLogin(phoneNumber, otp, ga_id) {
    const url = `/user/login`;
    const payload = {
      otp: Number(otp),
      phone_number: String(phoneNumber),
      gaid: ga_id, //AndroidService.getDeviceIdentifiers().ga_id,
    };
    const res = await apiAxiosV1.post(url, payload);
    return res?.data;
  },
  async handleMobileSignup({
    referralCode,
    utmSource,
    fullName,
    phoneNumber,
    otp,
    email,
  }) {
    const url = `/user/signup`;
    const payload = {
      phone_number: phoneNumber,
      otp: Number(otp),
      email: email,
      full_name: fullName,
      utm_source: utmSource,
      referral_code: referralCode,
      gaid: AndroidService.getDeviceIdentifiers().ga_id,
    };

    const res = await apiAxiosV1.post(url, payload);
    return res?.data;
  },
  async handleGoogleSignup({ code, referralCode, utmSource, fullName }) {
    const url = `/user/google_auth/signup`;
    const payload = {
      code,
      full_name: fullName,
      utm_source: utmSource,
      referral_code: referralCode,

      gaid: AndroidService.getDeviceIdentifiers().ga_id,
    };
    const res = await apiAxiosV1.post(url, payload);
    return res?.data;
  },

  async logout() {
    await AndroidService.signOut();
    StorageService.setAccessToken('');
    StorageService.setAuthRefreshToken('');
    StorageService.setWId('');
    StorageService.setUserId('');
    return;
  },
};
